<template>
  <div style="height: 50px; line-height: 0px; border-bottom: 1px solid #ccc;margin-bottom: -10px; display: flex">
<!--    <div style="width: 200px; padding-left: 30px; font-weight: bold; color: dodgerblue">后台管理</div>-->
    <div style="flex: 1"></div>
    <div style="width: 100px;margin-top: 20px">
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ user.username}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="$router.push('/person')">个人信息</el-dropdown-item>
            <el-dropdown-item @click="$router.push('/login')">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "Header",
  data() {
    return {
      path: this.$route.path,
      user: {}
    }
  },
  created() {
    let str = sessionStorage.getItem("user") || "{}"
    this.user = JSON.parse(str)

  }
}
</script>

<style scoped>

</style>
