import axios from 'axios'
import router from "@/router";

const request = axios.create({
    baseURL: "http://8.137.119.117:9090",
    timeout: 5000
})

// 请求白名单，如果请求在白名单里面，将不会被拦截校验权限
const whiteUrls = ["/user/login", '/user/register', '/article/page','/front/home','/front/Detail','/Home']

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';

     // config.headers['token'] = user.token;  // 设置请求头

    if (!whiteUrls.includes(config.url)) {  // 校验请求白名单
        // 取出sessionStorage里面缓存的用户信息
        let userJson = sessionStorage.getItem("user")
        // if (!userJson) {
        //     router.push("/front/home")
        // }
    }
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)


export default request
// 通过标识符取消拦截器
request.interceptors.request.eject(whiteUrls);
request.interceptors.response.eject(request);
// 添加拦截器时，会返回一个拦截器的标识符

