import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../layout/Layout.vue'

const routes = [
  {
    path: '/front',
    name: 'Front',
    component: () => import("@/layout/front"),
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import("@/views/front/Home"),
      },
      {
        path: 'introduction',
        name: 'introduction',
        component: () => import("@/views/front/introduction"),
      },
      {
        path: 'seek',
        name: 'seek',
        component: () => import("@/views/front/seek"),
      },
      {
        path: 'song',
        name: 'song',
        component: () => import("@/views/front/song"),
      },
      {
        path: 'detail',
        name: 'FrontDetail',
        component: () => import("@/views/front/Detail"),
      },
      {
        path: 'person',
        name: 'FrontPerson',
        component: () => import("@/views/Person"),
      },
    ]
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: "/front/home",
    children: [
      {
        path: 'home',
        name: 'EndHome',
        component: () => import("@/views/Home"),
      },
      {
        path: 'user',
        name: 'User',
        component: () => import("@/views/User"),
      },
      {
        path: 'person',
        name: 'Person',
        component: () => import("@/views/Person"),
      },
      {
        path: 'article',
        name: 'Article',
        component: () => import("@/views/article"),
      },
      {
        path: 'detail',
        name: 'ArticleDetail',
        component: () => import("@/views/article_detail"),
      },
      {
        path: 'message',
        name: 'endMessage',
        component: () => import("@/views/message"),
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/Login")
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import("@/views/Register")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
